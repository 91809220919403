<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-09-06 15:58:14
 * @LastEditors: cyy
 * @Description: 推广业绩 
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\promoter\achievement.vue
-->
<template>
  <div class="achievement">
    <el-radio-group v-model="menuType">
      <el-radio-button label="1">推广记录</el-radio-button>
      <el-radio-button label="2">业绩统计</el-radio-button>
    </el-radio-group>

    <div class="flex-center">
      <el-button type="medium" @click="exportTable">导出数据</el-button>
      <div class="header">
        <div class="heard_top mt20">
          <el-date-picker
            size="medium"
            v-model="orderTime"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            @change="paytimepick"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <el-input
          clearable
          size="medium"
          :placeholder="
            menuType == 2 && post.searchType == 2
              ? '输入分组名称搜索'
              : '输入名称搜索'
          "
          v-model="post.search"
          class="input-with-select mt20"
        >
          <div class="search_select" slot="prepend">
            <el-select
              style="width: 120px"
              v-model="post.searchType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </el-input>
      </div>
    </div>

    <!--推广记录-->
    <pagination2
      v-if="menuType == 1"
      key="list1"
      ref="list"
      :option="post"
      url="/Promoter/recordList"
      @complete="listCallback(arguments[0], '/Promoter/recordExcel')"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="成交时间" prop="number">
            <template slot-scope="{ row }">
              {{
                row.goods_order_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
              }}
            </template>
          </el-table-column>

          <el-table-column label="商品" prop="number" min-width="200">
            <template slot-scope="{ row }">
              <div class="cover flex-center">
                <img class="img" :src="row.goods_cover_url" alt="" />
                <div class="text">
                  <div class="t1">{{ row.goods_name }}</div>
                  <div class="t2">{{ row.goods_type | courseTypeText }}</div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="推广员信息" min-width="150">
            <template slot-scope="{ row }">
              <div class="paymember">
                <div class="member_photo">
                  <img :src="row.p_photo" alt="" />
                </div>
                <div class="font1" :title="row.p_name">
                  {{ row.p_name }}
                </div>
              </div>
              <div class="paymember mt10">
                <div class="font1" :title="row.p_realname">
                  真实姓名：{{ row.p_realname || '-' }}
                </div>
              </div>
              <div class="paymember mt10">
                <div class="font1" :title="row.p_mobile">
                  手机号：{{ row.p_mobile || '-' }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="成交客户信息" min-width="150">
            <template slot-scope="{ row }">
              <div class="paymember">
                <div class="member_photo">
                  <img :src="row.u_photo" alt="" />
                </div>
                <div class="font1" :title="row.u_uname">
                  {{ row.u_uname }}
                </div>
              </div>
              <div class="paymember mt10">
                <div class="font1" :title="row.u_realname">
                  真实姓名：{{ row.u_realname || '-' }}
                </div>
              </div>
              <div class="paymember mt10">
                <div class="font1" :title="row.u_mobile">
                  手机号：{{ row.u_mobile || '-' }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="金额和佣金" min-width="150">
            <template slot-scope="{ row }">
              <div>订单金额：￥{{ row.goods_order_price }}</div>
              <div>佣金金额：￥{{ row.commission }}</div>
              <div>佣金比例：{{ row.commission_percent }}%</div>
            </template>
          </el-table-column>

          <el-table-column label="分佣结算时间" prop="price">
            <template slot-scope="{ row }">
              <template v-if="row.settlement_time > 0">
                {{
                  row.settlement_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
                }}
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>

          <el-table-column label="结算天数" prop="price">
            <template slot-scope="{ row }">
              {{ row.settlement_days || '-' }}
            </template>
          </el-table-column>

          <el-table-column label="状态" prop="costprice">
            <template slot-scope="{ row }">
              <span v-if="row.settlement_status == 1" style="color: #ff3535">
                待结算
              </span>
              <span
                v-else-if="row.settlement_status == 2"
                style="color: #3d80ef"
              >
                已结算
              </span>
              <span v-else style="color: #ff9f27">已终止</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" min-width="130px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="orderDetail(row)">订单</el-button>
              <template v-if="row.settlement_status == 1">
                <span class="space">|</span>
                <el-button type="text" @click="terminationCalculation(row)">
                  终止结算
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!--推广业绩-->
    <pagination2
      v-if="menuType == 2"
      key="list2"
      :option="post"
      url="/Promoter/achievement"
      ref="list"
      @complete="listCallback(arguments[0], '/Promoter/achievementExcel')"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :data="tableData"
          @sort-change="sortChange"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="排名" prop="number" sortable="custom">
            <template slot-scope="{ row }">{{ row.sort }}</template>
          </el-table-column>

          <el-table-column label="推广员">
            <template slot-scope="{ row }">
              <div class="paymember">
                <div class="member_photo">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="font1">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="真实姓名" prop="p_realname">
            <template slot-scope="{ row }">
              {{ row.p_realname || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="手机号" prop="p_mobile">
            <template slot-scope="{ row }">
              {{ row.p_mobile || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="所属分组" prop="name">
            <template slot-scope="{ row }">
              {{ row.name || '-' }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="推广商品" prop="goods_num"></el-table-column> -->
          <el-table-column
            label="推广订单"
            prop="total_order_num"
          ></el-table-column>

          <el-table-column label="推广订单金额(元)">
            <template slot-scope="{ row }">
              ￥{{ row.total_order_price }}
            </template>
          </el-table-column>

          <el-table-column label="累计佣金(元)" prop="price">
            <template slot-scope="{ row }">
              ￥{{ row.total_commission }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'achievement',

  data() {
    return {
      menuType: '1',

      post: {
        searchType: 1,
        search: '',
        start_time: 0,
        end_time: 0,
        sort: 1,
      },

      exportInfo: {
        total: 0,
        url: '',
      },

      orderTime: '',
      options3: [],
    }
  },

  watch: {
    menuType: {
      handler(val) {
        if (val == 1) {
          this.options3 = [
            {
              value: 1,
              label: '推广员昵称',
            },
            {
              value: 2,
              label: '成交客户',
            },
          ]
        } else {
          this.options3 = [
            {
              value: 1,
              label: '推广员昵称',
            },
            {
              value: 2,
              label: '所属分组',
            },
          ]
        }
        this.post.searchType = 1
      },
      immediate: true,
    },
  },

  methods: {
    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.post.start_time = val[0] / 1000
      this.post.end_time = val[1] / 1000
    },

    // 获取列表数据回调
    listCallback(val, url) {
      this.exportInfo = {
        total: val.count || 0,
        url,
      }
    },

    // 导出
    exportTable() {
      if (this.exportInfo.total > 0) {
        this.$confirm(
          `确定要导出当前${this.exportInfo.total}条数据？`,
          '提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          let eleLink = document.createElement('a')
          eleLink.href = `${location.origin}${
            this.exportInfo.url
          }${this.objToStr(this.post)}`
          eleLink.click()
          eleLink.remove()
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    sortChange(value) {
      this.post = _.assign({}, this.post, {
        sort: !value.order ? 1 : value.order === 'ascending' ? 1 : 2,
      })
    },

    // 订单详情
    orderDetail(val) {
      if (val.goods_type == 5) {
        this.$router.push(
          `/capitalOrder/opencourseDetail/${val.goods_order_id}`
        )
      } else if (val.goods_type == 6) {
        this.$router.push({
          path: '/capitalOrder/graDetails',
          query: { id: val.g_order_id },
        })
      } else {
        this.$router.push(`/capitalOrder/detail?data_id=${val.goods_order_id}`)
      }
    },

    // 终止结算
    async terminationCalculation({ p_achievement_id }) {
      await this.$confirm(
        '终止结算后，当前推广佣金将不会进入推广员账户！',
        '终止结算',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
      await this.$http({
        url: '/Promoter/stopAchievement',
        data: {
          p_achievement_id,
        },
      })
      this.$root.prompt({
        msg: '操作成功',
        type: 'success',
      })
      this.$refs.list.reset()
      this.$emit('survey')
    },
  },
}
</script>

<style lang="scss" scoped>
.achievement {
  padding: 5px 20px 0;
}
.header {
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: -10px;
  ::v-deep .input-with-select .el-input__inner {
    width: 218px !important;
  }
  ::v-deep .search_select {
    .el-input__inner {
      width: 120px !important;
    }
  }
  ::v-deep .el-input-group {
    width: auto;
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
    }
  }
}
.heard_top {
  padding: 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  span {
    margin-right: 24px;
    min-width: 56px;
  }
  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
.paymember {
  display: flex;
  align-items: center;
  .member_photo {
    margin-right: 10px;
    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }
  .font1 {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cover {
  white-space: nowrap;
  .img {
    width: 80px;
    height: 45px;
    background: #f5f5f5;
    border-radius: 2px;
  }
  .text {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
    .t1 {
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .t2 {
      margin-top: 8px;
      font-size: 12px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 10px 0;
}
</style>
