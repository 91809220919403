<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-03-30 15:32:44
 * @LastEditors: cyy
 * @Description: 客户关系 =》 调整 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\promoter\achievement.vue
-->
<template>
  <div class="transcoddetail">
    <el-dialog
      class="dialogVisible"
      width="900px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      @close="close"
    >
      <div slot="title" class="flex-center">
        <div class="title_font">调整客户关系</div>
        <div class="redcolor tip">
          温馨提示：建议您与推广员沟通确认后，再调整客户关系。
        </div>
      </div>

      <div class="main">
        <div class="heard" :class="myHeaderInfo.uname ? '' : 'pt15 pb15'">
          <div class="top">
            <div class="font1">客户昵称：{{ value.uname }}</div>
            <template v-if="myHeaderInfo.uname">
              <div class="font1 flex-center">
                当前推广员：
                <img class="photo mr10 ml10" :src="myHeaderInfo.uphoto" />
                {{ myHeaderInfo.uname }}
              </div>
              <div class="font1">
                手机号：{{ myHeaderInfo.umobile || '无' }}
              </div>

              <div class="font2">
                <el-button @click="offUser" type="text">解绑推广员</el-button>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="mt20 mb20 flex-center search-input">
        <el-input
          v-model="search"
          placeholder="输入昵称搜索"
          size="medium"
        ></el-input>
      </div>
      <el-table
        empty-text="暂无数据"
        :data="myHeaderInfo.list || []"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
          height: '40px',
        }"
        style="width: 100%"
      >
        <el-table-column prop="transcode_type" label="推广员昵称" width="250">
          <template slot-scope="{ row }">
            <div class="flex-center">
              <img class="photo" :src="row.uphoto" alt="" />
              <div class="font1 ml10">
                {{ row.uname }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="p_realname" label="真实姓名"></el-table-column>
        <el-table-column prop="p_mobile" label="手机号"></el-table-column>
        <el-table-column prop="name" label="所属分组"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="bindUser(row)">绑定TA</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'adjustment',

  props: {
    value: Object,
  },

  data() {
    return {
      //弹窗的控制
      dialogstatus: true,

      search: '',

      myHeaderInfo: {},
    }
  },

  watch: {
    search() {
      this.headerInfo()
    },
  },

  created() {
    this.headerInfo()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    // 头部信息
    async headerInfo() {
      const { data } = await this.$http({
        url: '/Promoter/newCustomer',
        data: {
          search: this.search || '',
          p_customer_id: this.value.p_customer_id,
        },
      })
      this.myHeaderInfo = data
    },

    // 解绑
    async offUser() {
      await this.$confirm(
        '建议您与推广员沟通确认后，再调整客户关系！是否确定解绑？',
        '解绑推广员',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )

      await this.$http({
        url: '/Promoter/unbound',
        data: {
          p_customer_id: this.value.p_customer_id,
        },
        successMsg: true,
      })
      this.close()
    },

    // 绑定
    async bindUser(val) {
      await this.$http({
        url: '/Promoter/binding',
        data: {
          p_customer_id: this.value.p_customer_id,
          p_user_id: val.p_user_id,
        },
        successMsg: true,
      })
      this.headerInfo()
    },
  },
}
</script>
<style lang="scss" scoped>
.greycolor {
  color: #c0c4cc;
}

::v-deep .el-table__empty-text {
  width: 74%;
}

.title_font {
  font-size: 14px;
  color: #333333;
}

.tip {
  font-size: 13px;
  color: #ff3535;
  line-height: 13px;
  margin-left: 10px;
}

::v-deep .el-table__body-wrapper {
  height: calc(100% - 48px);
  overflow-y: auto;
}

::v-deep .el-table {
  height: 358px;
}

::v-deep .el-dialog {
  height: 550px;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #1b9d97;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 20px;
}

.transcoddetail {
  .main {
    box-sizing: border-box;

    .heard {
      font-size: 14px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #f5f5f5;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .font1 {
          font-size: 14px;
          color: #333333;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .font2 {
          align-items: center;
          display: flex;
          color: #1b9d97;
          line-height: 14px;
        }
      }

      .bottom {
        color: #666666;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
}
.photo {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.search-input {
  text-align: right;
  ::v-deep .el-input__inner {
    width: 215px;
    padding-left: 20px;
    background: none;
    border: 1px solid #dddddd;
  }
}
</style>
