<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:25
 * @LastEditTime: 2022-04-07 17:53:05
 * @LastEditors: cyy
 * @Description: 推广员管理
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\manage.vue
-->
<template>
  <div id="manage">
    <div class="template-copntain">
      <ul class="template-list">
        <li class="space_between">
          <div class="f_left">全部分组</div>
          <el-button type="text" class="mr10" @click="newGroup">
            新建分组
          </el-button>
        </li>
        <li
          v-for="item in groupList"
          :key="item.p_group_id"
          class="item"
          :class="{
            select:
              selectTemplate && item.p_group_id == selectTemplate.p_group_id,
          }"
        >
          <div class="t1" @click="selectTem(item)"></div>
          <div class="t2" @click="selectTem(item)">
            {{ item.name }}({{ item.count }})
            <span
              class="fs12 redcolor"
              style="line-height: 1.5; flex-shrink: 0"
              v-if="item.status == 1"
            >
              导入中
            </span>
          </div>
          <el-popover
            popper-class="promoter-manage-template-setting"
            placement="bottom"
            width="65"
            trigger="click"
            ref="popover"
          >
            <div class="template-setting">
              <div class="item" @click="editGroup(item, 1)">编辑</div>
              <div
                class="item"
                @click="delGroup(item)"
                v-if="item.is_default == 2"
              >
                删除
              </div>
            </div>
            <div slot="reference" class="t3"></div>
          </el-popover>
        </li>
      </ul>

      <div class="template-detail" v-if="loadding">
        <pagination2
          :option="post"
          url="/Promoter/promoterList"
          ref="childDialogBox"
          @complete="childDialogBoxcomplete"
        >
          <template v-slot:default="{ tableData }">
            <div class="flex-center template-info">
              <div class="my-title">
                {{
                  selectTemplate
                    ? selectTemplate.name + '(' + selectTemplate.count + ')'
                    : '出错啦'
                }}
              </div>
              <div class="timepicker">
                <el-select
                  v-model="post.status"
                  class="mr10"
                  style="width: 120px"
                  placeholder="请选择"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>

                <el-input
                  clearable
                  size="medium"
                  :placeholder="
                    post.searchType == 1
                      ? '输入昵称搜索'
                      : post.searchType == 2
                      ? '输入真实姓名搜索'
                      : '输入手机号搜索'
                  "
                  v-model="post.search"
                  style="width: 302px"
                  class="input-with-select"
                >
                  <el-select
                    style="width: 110px"
                    v-model="post.searchType"
                    slot="prepend"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-input>
              </div>
            </div>
            <div class="tem-name">
              <div class="btn_contain">
                <el-button type="primary" class="mr10" @click="addPromoterBtn">
                  添加推广员
                </el-button>
                <el-button type="text" @click="batchImport">批量导入</el-button>
                <div class="driver">|</div>
                <el-button type="text" @click="exportCvs">导出推广员</el-button>
                <div class="driver">|</div>
                <el-button type="text" @click="editGroup(selectTemplate, 2)">
                  分佣结算设置
                </el-button>
                <div class="driver">|</div>
                <el-button type="text" @click="promoteManagement">
                  推广商品管理({{ goods_num }})
                </el-button>
              </div>
            </div>
            <el-table
              :fit="true"
              :height="480"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="tableData"
            >
              <el-table-column label="推广员" min-width="130">
                <template slot-scope="{ row }">
                  <div class="people-contain" :title="row.uname">
                    <div class="cover-img">
                      <img :src="row.uphoto" alt="" v-if="row.status == 2" />
                    </div>
                    <div class="name">
                      {{ row.status == 1 ? '未关联' : row.uname }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="真实姓名">
                <template slot-scope="{ row }">
                  <span :title="row.p_realname">{{ row.p_realname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="手机号码" min-width="100">
                <template slot-scope="{ row }">
                  {{ row.p_mobile || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="数据概况" min-width="130px">
                <template slot-scope="{ row }">
                  <p>累计客户 {{ row.total_customer_num || 0 }} 人</p>
                  <p>累计佣金{{ row.total_commission }} 元</p>
                </template>
              </el-table-column>
              <el-table-column label="加入时间">
                <template slot-scope="{ row }">
                  <span v-if="row.c_time > 0">
                    {{ row.c_time | formatTimeStamp }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="{ row }">
                  <span v-if="row.status == 1">未激活</span>
                  <span v-else>已激活</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="right"
                min-width="140px"
                prop="address"
                fixed="right"
              >
                <template slot-scope="{ row }">
                  <div class="caozuo">
                    <el-button
                      slot="reference"
                      type="text"
                      @click="toPromoterDetail(row)"
                    >
                      详情
                    </el-button>
                    <div class="driver">|</div>
                    <el-button
                      slot="reference"
                      type="text"
                      @click="showMobileGroup(row)"
                    >
                      移动
                    </el-button>
                    <div class="driver">|</div>
                    <el-button
                      slot="reference"
                      type="text"
                      @click="delPromoter(row)"
                    >
                      清退
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
    </div>

    <!-- 移动分组 -->
    <mobile-grouping
      v-if="mobileGroupingStatus"
      :dialogstatus.sync="mobileGroupingStatus"
      :selectTemplate="selectTemplate"
      :mobileGrouplist="mobileGrouplist"
      @reset="reset"
    ></mobile-grouping>

    <!-- 新建分组/编辑分组 -->
    <new-group
      v-if="newGroupStatus"
      :dialogstatus.sync="newGroupStatus"
      @reset="reset"
      :editGroupVal="editGroupVal"
      :selecttype="selecttype"
    ></new-group>

    <!-- 添加推广员 -->
    <add-promoter
      v-if="addPromoterStatus"
      :selectTemplate="selectTemplate"
      :dialogstatus.sync="addPromoterStatus"
      @reset="reset"
    ></add-promoter>

    <router-view></router-view>
  </div>
</template>

<script>
import mobileGrouping from './components/mobileGrouping'
import addPromoter from './components/addPromoter'
import newGroup from './components/newGroup'
export default {
  name: 'manage',

  components: {
    newGroup,
    addPromoter,
    mobileGrouping,
  },

  props: {
    serverdata: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loadding: true,

      selecttype: '',

      // 推广商品管理
      goods_num: '',

      //  当前编辑分组的信息
      editGroupVal: {},

      //所要移动的分组
      mobileGrouplist: {},

      // 移动分组弹窗
      mobileGroupingStatus: false,

      // 当前选择的分组
      selectTemplate: {},

      // 添加推广员弹窗
      addPromoterStatus: false,

      // 分组列表
      groupList: null,

      // 添加分组
      newGroupStatus: false,

      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '未激活',
        },
        {
          value: 2,
          label: '已激活',
        },
      ],

      options2: [
        {
          value: 1,
          label: '昵称',
        },
        {
          value: 2,
          label: '真实姓名',
        },
        {
          value: 3,
          label: '手机号',
        },
      ],

      post: {
        p_group_id: '',
        searchType: 1,
        status: 0,
        search: '',
      },
    }
  },

  created() {
    this.getgroupList()
  },

  methods: {
    newGroup() {
      this.selecttype = 1
      this.$nextTick(() => {
        this.newGroupStatus = true
      })
    },

    // 推广员列表数据
    childDialogBoxcomplete(val) {
      this.goods_num = val.goods_num
      this.count = val.count
    },

    // 导出推广员
    async exportCvs() {
      if (this.count > 0) {
        this.$confirm(`确定要导出当前${this.count}条数据？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            const obj = _.assign({}, this.post, {
              p_group_id: this.selectTemplate.p_group_id,
            })
            let eleLink = document.createElement('a')
            eleLink.href =
              `${location.origin}/Promoter/exportCvs` + this.objToStr(obj)
            eleLink.click()
            eleLink.remove()
          })
          .catch(() => {})
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    //推广商品管理
    promoteManagement() {
      this.$router.push({
        path: '/promoter/promoteManagement',
        query: {
          group_id: this.selectTemplate.p_group_id,
          groupName: this.selectTemplate.name,
        },
      })
    },

    // 批量分组
    batchImport() {
      this.$router.push({
        path: '/promoter/batchImport',
        query: {},
      })
    },

    // 删除分组
    async delGroup(val) {
      await this.$confirm(
        '删除后，该分组的推广员自动变成默认分组的推广员，结算设置和推广内容都变更为默认分组的设置！',
        '删除分组',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
      await this.$http({
        url: '/Promoter/delGroup',
        data: { p_group_id: val.p_group_id },
      })
      // 如果删除的是当前选中的分组，那么将重新定位到分组的第一个数据
      if (val.p_group_id == this.selectTemplate.p_group_id) {
        this.selectTemplate = this.groupList[0]
      }
      this.reset()
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
    },

    // 编辑分组
    editGroup(val, val2) {
      this.editGroupVal = val
      this.$nextTick(() => {
        this.selecttype = val2
        this.newGroupStatus = true
      })
    },

    // 清退
    async delPromoter(val) {
      await this.$confirm(
        '清退后，该推广员将失去推广员角色，可进入推广页查看已获得的奖励和提现，无法查看任何可推广的内容！',
        '清退',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
      await this.$http({
        url: '/Promoter/delPromoter',
        data: { p_user_id: val.p_user_id },
      })
      this.reset()
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.$emit('survey')
    },

    // 分组完成后进行重置
    reset() {
      this.editGroupVal = {}
      this.getgroupList(2)
      this.post.p_group_id = this.selectTemplate.p_group_id
      this.$refs.childDialogBox.reset()
      this.$emit('survey')
    },

    // 移动分组
    showMobileGroup(row) {
      this.mobileGroupingStatus = true
      this.$nextTick(() => {
        this.mobileGrouplist = row
      })
    },

    // 选择的分组
    selectTem(val) {
      this.selectTemplate = val
      this.post.p_group_id = val.p_group_id
      this.getgroupList(2)
    },

    // 推广员分组
    async getgroupList(val) {
      this.loadding = false
      const { data } = await this.$http({
        url: '/Promoter/groupList',
        data: {},
      })
      this.loadding = true
      this.groupList = data

      // 不许要重置数据
      if (val != 2) {
        this.selectTemplate = data[0]
        this.post.p_group_id = this.selectTemplate.p_group_id
      }
    },

    // 添加推广员
    addPromoterBtn() {
      this.addPromoterStatus = true
    },

    // 详情
    toPromoterDetail(row) {
      console.log(row)
      this.$router.push({
        path: '/promoter/promoterDetail',
        query: { p_user_id: row.p_user_id },
      })
    },
  },
}
</script>




<style lang="scss" scoped>
.template-setting {
  .item {
    font-size: 14px;
    color: #2f2f2f;
    padding: 7px 0;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
#manage {
  padding: 10px 20px 20px;
  img {
    width: 100%;
    height: 100%;
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
  .template-copntain {
    display: flex;

    .driver {
      margin: 0 10px;
      flex-shrink: 0;
      color: #0aa29b;
      height: 100%;
    }
    .template-list {
      overflow: auto;
      min-width: 270px;
      width: 270px;
      height: 817px;
      min-height: 300px;
      border: 1px solid #eaeaea;
      .space_between {
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0 0 0 14px;
        .f_left {
          font-size: 15px;
          font-weight: bold;
          color: #333333;
        }
      }
      .item {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0 0 0 14px;
        &:hover {
          background: #eee;
        }
        &.select {
          background: #f5f5f5;
        }
        .t1 {
          width: 16px;
          height: 13px;
          background: url('~@ass/img/1.4.6/icon_xxmb_xx.png') no-repeat;
          background-size: 16px;
        }
        .t2 {
          flex: 1;
          word-break: break-all;
          font-size: 14px;
          color: #333333;
          padding: 10px 0 10px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .t3 {
          width: 44px;
          height: 13px;
          background: url('~@ass/img/1.4.6/icon_xxmb_gd.png') no-repeat center;
          background-size: 16px;
        }
      }
    }
    .template-detail {
      overflow: hidden;
      flex: 1;
      margin-left: 20px;
      ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
        background-color: rgba(0, 0, 0, 0) !important;
      }
      .tem-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 20px;
        .btn_contain {
          display: flex;
          align-items: center;
          margin-top: 20px;
        }
      }
      .people-contain {
        display: flex;
        align-items: center;
        .cover-img {
          width: 24px;
          height: 24px;
          min-width: 24px;
          border-radius: 50%;
          margin-right: 10px;
          background: #c5c5c5;
          flex-shrink: 0;
        }
        .name {
          flex: 1;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      ::v-deep .select-send-time {
        .el-date-editor {
          width: 128px;
        }
        .el-input__inner {
          border: 0;
        }
      }
      ::-webkit-scrollbar {
        width: 2px;
        height: 8px;
        background-color: #fff;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      ::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
        background-color: #fff;
      }

      /*定义滑块 内阴影+圆角*/
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .add-msg {
          width: 150px;
          line-height: 46px;
          background: #0aa29b;
          border-radius: 4px;
          font-size: 15px;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
        .tips {
          font-size: 12px;
          color: #666666;
          line-height: 16px;
          margin-left: 20px;
        }
      }
    }
    .footer {
      margin-top: 20px;
      .total {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .template-setting {
    .item {
      font-size: 14px;
      color: #2f2f2f;
      padding: 7px 0;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
  ::v-deep .caozuo {
    font-size: 12px;
    color: #0aa29b;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sp_drive {
      margin: 0 5px;
    }
    .el-button {
      font-size: 12px;
      color: #0aa29b;
      &.is-disabled {
        color: #c0c4cc;
      }
    }
  }
}
.template-info {
  justify-content: space-between;
  .my-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
}
</style>

<style>
.promoter-manage-template-setting {
  min-width: auto !important;
}
</style>