<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-04-06 17:13:12
 * @LastEditors: cyy
 * @Description: 客户关系 
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\customerRelations.vue
-->
<template>
  <div class="customerRelations">
    <el-radio-group v-model="menuType">
      <el-radio-button label="1">客户查询</el-radio-button>
      <el-radio-button label="2">客户绑定设置</el-radio-button>
    </el-radio-group>
    <template v-if="menuType == 2">
      <!--客户绑定设置-->
      <div class="flex-center bind-validity-time">
        <div class="title">客户绑定有效期</div>
        <el-radio-group v-model="post2.type">
          <el-radio :label="1">永久有效</el-radio>
          <el-radio :label="2">绑定</el-radio>
        </el-radio-group>
        <el-input-number
          :step="1"
          :step-strictly="true"
          size="medium"
          v-model="post2.expire_days"
          controls-position="right"
          :min="1"
          :max="999"
          @click.native="post2.type = 2"
        ></el-input-number>
        <span style="margin: 0 5px">天内有效</span>
      </div>
      <el-button
        @click="bindValidityDialog = true"
        type="primary"
        style="width: 88px; margin-top: 40px"
      >
        保存
      </el-button>
    </template>
    <!---客户关系列表-->
    <template v-if="menuType == 1">
      <div class="flex-center">
        <div class="header">
          <div class="heard_top mt20">
            <el-select
              size="medium"
              style="width: 120px"
              v-model="post.status"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-input
            clearable
            size="medium"
            placeholder="输入名称搜索"
            v-model="post.search"
            class="input-with-select mt20"
          >
            <div class="search_select" slot="prepend">
              <el-select
                style="width: 120px"
                v-model="post.searchType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-input>
        </div>
      </div>
      <pagination2 ref="list" :option="post" url="/Promoter/customerList">
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="客户">
              <template slot-scope="{ row }">
                <div class="paymember">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="font1">
                    {{ row.uname }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="真实姓名" prop="student_remarks">
              <template slot-scope="{ row }">
                {{ row.student_remarks || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="手机号" prop="umobile">
              <template slot-scope="{ row }">
                {{ row.umobile || '-' }}
              </template>
            </el-table-column>

            <el-table-column label="所属推广员">
              <template slot-scope="{ row }">
                <div class="paymember">
                  <div class="member_photo">
                    <img :src="row.p_photo" alt="" />
                  </div>
                  <div class="font1" :title="row.p_name">
                    {{ row.p_name }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="真实姓名" prop="p_realname">
              <template slot-scope="{ row }">
                <div :title="row.p_realname" class="font1">
                  {{ row.p_realname || '-' }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="推广员手机号" prop="p_mobile">
              <template slot-scope="{ row }">
                {{ row.p_mobile || '-' }}
              </template>
            </el-table-column>

            <el-table-column label="最近绑定时间" prop="number">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>

            <el-table-column label="当前状态" prop="costprice">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1" style="color: #3d80ef">
                  绑定中
                </span>
                <span v-else style="color: #ff3535">已解绑</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <template v-if="row.status == 1">
                  <el-button type="text" @click="adjustment(row)">
                    调整
                  </el-button>
                  <span class="space">|</span>
                </template>
                <el-button type="text" @click="changeRecord(row)">
                  变更记录
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>
    <!--变更客户绑定有效期-->
    <el-dialog
      title="变更客户绑定有效期"
      :visible.sync="bindValidityDialog"
      :close-on-click-modal="false"
      width="422px"
      class="bindValidityDialog"
    >
      <div class="prompt">
        变更客户绑定有效期需问隔24小时。修改后，绑定中的客户有效期将自动更新。
      </div>
      <div class="input">
        <el-input
          size="medium"
          v-model.trim="bindValidityConfirm"
          placeholder="输入“ 确定修改 ”进行确认（不含引号）"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer flex-center">
        <div class="t1">请输入 确定修改</div>
        <div class="btns">
          <el-button
            @click=";(bindValidityDialog = false), (bindValidityConfirm = '')"
          >
            取消
          </el-button>
          <el-button type="primary" @click="bindValidityComplete">
            确定
          </el-button>
        </div>
      </span>
    </el-dialog>
    <!--客户关系-->
    <adjustment
      v-if="showAdjustment"
      :value="showAdjustment"
      @close="adjustment"
    ></adjustment>
    <!--变更记录-->
    <changeRecord
      v-if="showChangeRecord"
      :value="showChangeRecord"
      @close="changeRecord"
    ></changeRecord>
  </div>
</template>

<script>
import adjustment from './adjustment'
import changeRecord from './changeRecord'

export default {
  name: 'customerRelations',

  components: {
    adjustment,
    changeRecord,
  },

  data() {
    return {
      menuType: '1',

      post: {
        searchType: 1,
        search: '',
        status: 0,
      },

      post2: {
        type: 1,
        expire_days: 5,
      },

      options2: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '绑定中',
        },
        {
          value: 2,
          label: '已解绑',
        },
      ],
      options3: [
        {
          value: 1,
          label: '客户名称',
        },
        {
          value: 2,
          label: '推广员',
        },
      ],

      bindValidityConfirm: '',
      bindValidityDialog: false,

      showAdjustment: null,
      showChangeRecord: null,
    }
  },

  watch: {
    // 切换菜单 获取客户绑定设置
    menuType() {
      if (this.menuType == 2) {
        this.$http({
          url: '/Promoter/customerSetting',
          callback: ({ data }) => {
            this.post2 = data
          },
        })
      }
    },
  },

  methods: {
    // 确认修改客户绑定时间
    async bindValidityComplete() {
      if (this.bindValidityConfirm === '确定修改') {
        await this.$http({
          url: '/Promoter/customerSetting',
          data: this.post2,
          successMsg: true,
        })
        this.bindValidityConfirm = ''
        this.bindValidityDialog = false
      } else {
        this.$root.prompt('输入内容有误！')
      }
    },

    // 调整
    adjustment(row) {
      this.showAdjustment = row ? row : null
      if (!row) {
        this.resetList()
      }
    },

    // 变更记录
    changeRecord(row) {
      this.showChangeRecord = row ? row : null
      if (!row) {
        this.resetList()
      }
    },

    resetList() {
      this.$refs.list.reset()
      this.$emit('survey')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio-button__inner {
  width: 125px;
}
.customerRelations {
  padding: 5px 20px 20px;
}
.header {
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: -10px;
  ::v-deep .input-with-select .el-input__inner {
    width: 218px !important;
  }
  ::v-deep .search_select {
    .el-input__inner {
      width: 120px !important;
    }
  }
  ::v-deep .el-input-group {
    width: auto;
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
    }
  }
}
.heard_top {
  padding: 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  span {
    margin-right: 24px;
    min-width: 56px;
  }
  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
.paymember {
  display: flex;
  align-items: center;
  .member_photo {
    margin-right: 10px;
    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }
  .font1 {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.bind-validity-time {
  font-size: 14px;
  color: #333333;
  margin-top: 30px;
  .title {
    margin-right: 30px;
  }
  ::v-deep .el-input-number {
    margin: 0 10px;
  }
}
.bindValidityDialog {
  .prompt {
    font-size: 14px;
    color: #ff3535;
    line-height: 19px;
    margin-top: -10px;
  }
  .input {
    margin-top: 10px;
  }
  .dialog-footer {
    margin-top: -10px;
    .t1 {
      flex: 1;
      font-size: 14px;
      color: #ff3535;
      line-height: 19px;
      text-align: left;
    }
    .btns {
    }
  }
}
.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 10px 0;
}
</style>