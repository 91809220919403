<!--
 * @Author: cyy
 * @version: Do not edit
 * @Date: 2022-03-29 11:33:06
 * @LastEditTime: 2022-04-06 16:14:12
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\main.vue
-->
<template>
  <div id="promoter">
    <div v-if="$route.path == '/promoter'">
      <div id="membership" v-if="$store.state.userInfo.is_promoter == '2'">
        <div class="heardermembership">
          <div class="tu">
            <img src="@ass/img/1.4.6.4/icon_tgy.png" alt="" />
          </div>

          <div class="right">
            <div class="right_font1">推广员</div>
            <div class="right_font2">
              是一款能让商家进行裂变传播、维系客户、促进成交的营销工具，客户成交后，推广员可获得相应的奖励。
            </div>
            <div class="right_button">
              <el-button
                type="primary"
                size="medium"
                @click="kaitong"
                style="width: 112px"
              >
                立即开通
              </el-button>
            </div>
          </div>
        </div>

        <!-- <noOpen :list="list"></noOpen> -->

        <openingfunction
          v-if="status3"
          :dialogstatus.sync="status3"
        ></openingfunction>
      </div>

      <div v-else>
        <div class="data_overview">
          <div class="title flex-center">
            数据概况
            <div class="getDataTime">
              统计时间：{{
                serverdata.time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
              }}
            </div>
          </div>
          <div class="flexBox">
            <flexBox1 :list="headerData(serverdata)"></flexBox1>
          </div>
          <div class="flexBox">
            <flexBox1 :list="headerData2(serverdata)"></flexBox1>
          </div>
        </div>
        <div class="guide">
          <div class="f-Basics mr10">
            引导推广员激活账号：有
            <span class="f-bold">{{ serverdata.not_active_num }}</span>
            位用户账号尚末激活
          </div>
          <el-button type="text f-bold" @click="toview">查看引导方式</el-button>
        </div>

        <div class="main_menu">
          <el-tabs v-model="activeName">
            <el-tab-pane
              v-for="item in menulist"
              :key="item.name"
              :label="item.label"
              :name="item.name"
            ></el-tab-pane>
          </el-tabs>
          <component
            :is="componentName"
            :serverdata="serverdata"
            @survey="survey"
          ></component>
        </div>

        <!-- 查看引导方式 -->
        <boot-activation
          v-if="viewBoot"
          :serverdata="serverdata"
          :dialogstatus.sync="viewBoot"
        ></boot-activation>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import manage from './manage'
import achievement from './achievement'
import customerRelations from './customerRelations'
import bootActivation from './components/bootActivation'
import flexBox1 from '@/components/flex/flexBox1'
import bg from '@/mixin/background'
import noOpen from '@/components/copyrightprotect/noOpen' //功能介绍
import openingfunction from '@/components/copyrightprotect/openingfunction'
export default {
  name: 'promoter',

  mixins: [bg],

  components: {
    flexBox1,
    bootActivation,
    manage,
    achievement,
    customerRelations,
    noOpen,
    openingfunction,
  },

  data() {
    return {
      status3: false,

      list: {
        function_tu: require('@/assets/img/1.4.0.6/img_zybjs@2x.png'),
      },

      serverdata: {},

      menulist: [
        {
          label: '推广员管理',
          name: '1',
        },
        {
          label: '推广业绩',
          name: '2',
        },
        {
          label: '客户关系',
          name: '3',
        },
      ],

      activeName: '1',

      viewBoot: false,
    }
  },

  created() {
    this.survey()
  },

  computed: {
    componentName() {
      let name
      switch (Number(this.activeName)) {
        case 2:
          name = 'achievement'
          break
        case 3:
          name = 'customerRelations'
          break
        default:
          name = 'manage'
      }
      return name
    },
  },

  methods: {
    kaitong() {
      this.status3 = !this.status3
    },

    // 引导
    toview() {
      this.viewBoot = true
    },

    // 数据概况
    async survey() {
      const { data } = await this.$http({
        url: '/Promoter/survey',
        data: {},
      })
      this.serverdata = data
    },

    // 一些基本数据
    headerData(val) {
      return [
        {
          top: '推广员数',
          bottom: val.user_num || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，网校推广员总数（不含已清退推广员）',
        },
        {
          top: '已绑定客户',
          bottom: val.total_customer_num || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，网校绑定客户总数（不含已解绑客户）',
        },
        {
          top: '总推广订单',
          bottom: val.order_num || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，网校累计成功支付的推广员订单总数量',
        },
        {
          top: '总订单金额(元)',
          bottom: val.total_order_price || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，网校累计成功支付的推广员订单总金额',
        },
        {
          top: '总佣金(元)',
          bottom: val.total_commission || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，所有已结算+未结算的佣金总额',
        },
        {
          top: '未结算佣金(元)',
          bottom: val.unsettled_commission || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，处于冻结期未结算的佣金总额',
        },
      ]
    },

    // 一些基本数据
    headerData2(val) {
      return [
        {
          top: '可提现佣金(元)',
          bottom: val.available_commission || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，已结算并可以提现的佣金总额',
        },
        {
          top: '已提现佣金(元)',
          bottom: val.obtained_commission || 0,
          name: Math.random(),
          prompt: '截至当前统计时间，已经被推广员提现的佣金总额',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
#promoter {
  padding: 20px;
  .heardermembership {
    background-color: #fff;
    padding: 20px;
    display: flex;
    margin-bottom: 20px;

    .tu {
      width: 131px;
      height: 131px;
      background: #e8f3ff;
      border-radius: 20px;

      img {
        height: 131px;
        width: 131px;
      }
    }
    .right {
      width: calc(100% - 161px);
      margin-left: 30px;

      .right_font1 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 18px;
      }

      .right_font2 {
        height: 40px;
        margin-top: 14px;
        margin-bottom: 21px;
        font-size: 13px;
        color: #808080;
        line-height: 20px;
      }

      .right_button {
        display: flex;
        align-items: flex-end;

        .price {
          font-size: 18px;
          font-weight: bold;
          color: #ff3535;
          margin-left: 24px;
          margin-right: 12px;
        }

        .strick {
          font-size: 13px;
          text-decoration: line-through;
          color: #808080;
          line-height: 13px;
        }
      }
    }
  }
  .data_overview {
    padding: 20px;
    background-color: #fff;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      .getDataTime {
        font-size: 12px;
        color: #a1a1a1;
        margin-left: 10px;
        line-height: 15px;
        font-weight: 500;
      }
    }
    ::v-deep .flexBox {
      margin: 30px 0 10px;
      .flex-box1 {
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 16.67% !important;
          flex: none;
        }
      }
    }
  }
  .guide {
    background-color: #fff;
    padding: 12px 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    .f-Basics {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .f-bold {
      color: #0aa29b;
      font-weight: bold;
    }
  }
  ::v-deep .main_menu {
    background-color: #fff;
    .el-tabs__nav-wrap {
      padding: 0 30px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
}
</style>