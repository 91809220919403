<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-30 16:16:07
 * @LastEditTime: 2022-03-30 17:15:36
 * @LastEditors: cyy
 * @Description: 移动分组
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\promoter\components\mobileGrouping.vue
-->
<template>
  <div id="mobileGrouping">
    <el-dialog
      title="移动分组"
      class="dialogVisible"
      width="422px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-select v-model="selectValue" placeholder="请选择" style="display:block;">
          <el-option
            v-for="item in options"
            :key="item.p_group_id"
            :label="item.name"
            :value="item.p_group_id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="redcolor fs12">
          一位推广员只可在一个组，每个组可以添加不同的商品设置佣金比例
        </div>
        <div class="btn">
          <el-button @click="cancel" size="medium">取 消</el-button>
          <el-button type="primary" size="medium" @click="submit">
            确 定
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'mobileGrouping',

  data() {
    return {
      options: [],
      selectValue: '',
    }
  },

  props: {
    selectTemplate: {
      type: Object,
      default: () => {},
    },
    mobileGrouplist: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getgroupList()
  },

  methods: {
    async submit() {
      await this.$http({
        url: '/Promoter/editPromoter',
        data: {
          p_user_id: this.mobileGrouplist.p_user_id,
          p_group_id: this.selectValue,
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.cancel()
    },

    // 推广员分组
    async getgroupList() {
      const { data } = await this.$http({
        url: '/Promoter/groupList',
        data: {},
      })
      this.options = data
      this.selectValue = this.selectTemplate.p_group_id
    },
    
    cancel() {
      this.$emit('reset')
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#mobileGrouping {
  ::v-deep .el-dialog__header {
    padding-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    padding-top: 20px;
  }
  ::v-deep .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left !important;
    .btn {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }
}
</style>