<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 15:10:37
 * @LastEditTime: 2022-03-30 15:32:44
 * @LastEditors: cyy
 * @Description: 客户关系 =》 变更记录 
 * @FilePath: \ teacher.dingdingkaike\src\views\MarketingCenter\promoter\achievement.vue
-->
<template>
  <div class="transcoddetail">
    <el-dialog
      class="dialogVisible"
      width="900px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      @close="close"
    >
      <div slot="title" class="flex-center">
        <div class="title_font">变更记录</div>
      </div>

      <div class="main">
        <div class="heard">
          <div class="top">
            <div class="font1">客户昵称：{{ value.uname }}</div>
            <template v-if="value.p_name && value.status == 1">
              <div class="font1 flex-center">
                当前推广员：
                <img class="photo mr10 ml10" :src="value.p_photo" />
                {{ value.p_name }}
              </div>
              <div class="font1">手机号：-</div>
            </template>
            <template v-else>
              <div class="font1 flex-center">当前推广员：-</div>
              <div class="font1">手机号：-</div>
            </template>
          </div>
        </div>
      </div>
      <template>
        <el-table
          empty-text="暂无数据"
          :data="list"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
            height: '40px',
          }"
          style="width: 100%"
        >
          <el-table-column
            prop="transcode_type"
            label="变更前推广员"
            width="250"
          >
            <template slot-scope="{ row }">
              <div v-if="row.before_uname" class="flex-center">
                <img class="photo" :src="row.before_uphoto" alt="" />
                <div class="font1 ml10">
                  {{ row.before_uname }}
                </div>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="transcode_type" label="变更后推广员">
            <template slot-scope="{ row }">
              <div class="flex-center">
                <img class="photo" :src="row.uphoto" alt="" />
                <div class="font1 ml10">
                  {{ row.uname }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="video_spec" label="客户关系更新时间">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="变更原因">
            <template slot-scope="{ row }">
              {{
                row.reason == 1
                  ? '首次绑定'
                  : row.reason == 2
                  ? '手动调整'
                  : '重新绑定'
              }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'changeRecord',

  props: {
    value: Object,
  },

  data() {
    return {
      list: [],
      //弹窗的控制
      dialogstatus: true,
    }
  },

  created() {
    this.getData()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    // 获取变更记录数据
    async getData() {
      const { data } = await this.$http({
        url: '/Promoter/promoterCustomerLogs',
        data: this.value,
      })
      this.list = data
    },
  },
}
</script>
<style lang="scss" scoped>
.greycolor {
  color: #c0c4cc;
}

::v-deep .el-table__empty-text {
  width: 74%;
}

.title_font {
  font-size: 14px;
  color: #333333;
}

.tip {
  font-size: 13px;
  color: #ff3535;
  line-height: 13px;
  margin-left: 10px;
}

::v-deep .el-table__body-wrapper {
  height: calc(100% - 48px);
  overflow-y: auto;
}

::v-deep .el-table {
  height: 358px;
}

::v-deep .el-dialog {
  height: 550px;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #1b9d97;
}

::v-deep .el-input__inner {
  font-size: 14px;
  border: 0;
  color: #1b9d97;
  background: #f5f5f5;
  padding-right: 0;
  padding-left: 0;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 20px;
}

.transcoddetail {
  .main {
    box-sizing: border-box;

    .heard {
      font-size: 14px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #f5f5f5;
      margin-bottom: 20px;
      padding: 15px 20px;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .font1 {
          flex: 1;
          font-size: 14px;
          color: #333333;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .font2 {
          align-items: center;
          display: flex;
          color: #1b9d97;
          line-height: 14px;
        }
      }

      .bottom {
        color: #666666;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
}
.photo {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50%;
}
</style>
