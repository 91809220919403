<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 17:07:11
 * @LastEditTime: 2022-04-07 10:16:43
 * @LastEditors: cyy
 * @Description: 新建分组
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\components\newGroup.vue
-->
<template>
  <div id="newGroup">
    <el-dialog
      :title="isedit ? '编辑分组' : '新建分组'"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="flex-center">
        <span v-if="selecttype == 1">
          {{ isedit ? '编辑分组' : '新建分组' }}
        </span>
        <span v-else>分佣结算</span>
        <span class="ml10 redcolor fs12">
          一位推广员只可在一个组，每个组可以添加不同的商品设置佣金比例
        </span>
      </div>
      <div class="dialogVisible_bottom">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item label="分组名称" :required="true">
            <el-input
              placeholder="请输入分组名称"
              v-model="ruleForm.name"
              style="width: 360px"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="分佣结算" :required="true">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="1">立即结算</el-radio>
              <el-radio :label="2">交易成功后</el-radio>
            </el-radio-group>
            <el-input-number
              class="ml10"
              v-model="ruleForm.settlement_days"
              controls-position="right"
              :min="1"
              :max="90"
              :step="1"
              :precision="0"
              @click.native="ruleForm.type = 2"
            ></el-input-number>
            天结算
            <div class="tip">
              <p>
                {{
                  ruleForm.type == 1
                    ? '交易完成后，立即给推广员发放奖励'
                    : `交易完成后，奖励冻结期满${ruleForm.settlement_days}天再给推广员发放奖励，冻结期支持设置1-90天`
                }}
              </p>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" style="width: 74px" size="medium">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="submit"
          style="width: 74px"
          size="medium"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'newGroup',

  data() {
    return {
      ruleForm: {
        name: '',
        type: 1,
        settlement_days: 7,
      },
    }
  },

  props: {
    // 1，新建或编辑分组 2是分佣结算
    selecttype: {
      type: Number,
      default: 1,
    },

    editGroupVal: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isedit() {
      return Object.keys(this.editGroupVal).length > 0
    },
  },

  created() {
    if (this.isedit) {
      this.editGroup()
    }
  },

  methods: {
    // 获取当前编辑分组的数据
    async editGroup() {
      const { data } = await this.$http({
        url: `/Promoter/editGroup?p_group_id=${this.editGroupVal.p_group_id} `,
      })
      this.ruleForm = _.assign({}, this.ruleForm, data)
      if (this.ruleForm.settlement_days > 0) {
        this.ruleForm.type = 2
      } else {
        this.ruleForm.type = 1
      }
    },

    // 保存最终结果
    async submit() {
      if (!this.ruleForm.name.trim()) {
        this.$root.prompt('请输入分组名称')
        return
      }

      if (this.ruleForm.type == 1) {
        this.ruleForm = _.assign({}, this.ruleForm, { settlement_days: 0 })
      }

      if (this.isedit) {
        await this.saveEditGroup()
      } else {
        await this.addGroup()
      }

      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.cancel()
    },

    // 保存编辑分组
    async saveEditGroup() {
      await this.$http({
        url: '/Promoter/editGroup',
        data: this.ruleForm,
      })
    },

    // 保存新建分组
    async addGroup() {
      await this.$http({
        url: '/Promoter/addGroup',
        data: this.ruleForm,
      })
    },

    reset() {
      this.ruleForm = {
        name: '',
        type: 1,
        num: 0,
      }
    },

    cancel() {
      // 重置当前组件数据
      this.reset()
      // 更新父组件数据
      this.$emit('reset')
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#newGroup {
  ::v-deep .el-dialog__header {
    padding-bottom: 0px !important;
  }
  .dialogVisible_bottom {
    .el-form-item {
      margin-bottom: 30px;
    }
    .tip {
      width: 490px;
      font-size: 14px;
      color: #666666;
      line-height: 1.5;
      margin-top: 10px;
    }
  }
}
</style>