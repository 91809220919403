<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 14:27:52
 * @LastEditTime: 2022-04-06 11:09:38
 * @LastEditors: cyy
 * @Description: 引导推广员激活账号
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\components\bootActivation.vue
-->
<template>
  <div id="bootActivation">
    <el-dialog
      title="引导推广员激活账号"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="f-basic">
          有
          <span class="f-bold">{{ serverdata.not_active_num }}</span>
          位用户账号尚末激活，请将以下二维码或链接发送给待激活用户并引导其绑定手机号，绑定成功即可成为推广员
        </div>
        <div class="code_link">
          <div class="code">
            <div class="ewm" id="qrcode"></div>
            <el-button type="text" class="mt10" @click="downEwm">
              下载二维码
            </el-button>
          </div>
          <div class="link">
            <div class="contain">{{ serverdata.url }}</div>
            <el-button type="text" class="mt10 address-btn">复制链接</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from '@/assets/js/qrcode'
import Clipboard from 'clipboard'
export default {
  name: 'bootActivation',

  data() {
    return {}
  },

  props: {
    serverdata: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.clipboard()
    this.$nextTick(() => {
      this.create_ewm()
    })
  },

  methods: {
    // 下载二维码
    downEwm() {
      if (!this.qrcode) {
        return
      }
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },

    // 生成二维码
    create_ewm() {
      const size = 150
      //生成前先清空原有的，不然会有多张二维码的情况
      document.getElementById('qrcode').innerHTML = ''
      //清空已有的海报的路径
      this.resultPic = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.serverdata.url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    // 复制PC地址
    clipboard() {
      const self = this
      const clipboard = new Clipboard('.address-btn', {
        text() {
          return self.serverdata.url //被复制的链接
        },
      })

      clipboard.on('success', () => {
        self.$root.prompt({
          type: 'success',
          msg: '复制成功',
        })
      })

      clipboard.on('error', () => {
        self.$root.prompt('复制失败')
      })
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>


<style lang="scss" scoped>
#bootActivation {
  .f-basic {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 24px;
    width: 605px;
    margin: 0 auto 54px;
    .f-bold {
      color: #0aa29b;
      font-weight: bold;
    }
  }
  .code_link {
    display: flex;
    justify-content: center;
    .code {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
      .ewm {
        width: 155px;
        height: 155px;
      }
    }
    .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      .contain {
        width: 370px;
        height: 155px;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 18px 20px;
        box-sizing: border-box;
      }
    }
  }
}
</style>