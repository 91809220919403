<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 18:22:24
 * @LastEditTime: 2022-04-07 10:28:24
 * @LastEditors: cyy
 * @Description: 添加推广员
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\components\addPromoter.vue
-->
<template>
  <div id="addPromoter">
    <el-dialog
      title="添加推广员"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="120px"
          label-position="left"
          class="demo-ruleForm"
        >
          <el-form-item label=" 推广分组" :required="true">
            <el-select
              v-model="ruleForm.p_group_id"
              style="width: 360px"
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in groupList"
                :key="item.p_group_id"
                :label="item.name"
                :value="item.p_group_id"
              ></el-option>
            </el-select>
            <span class="tip">
              结算方式：{{
                settlement_days > 0 ? `${settlement_days}天后结算` : '立即结算'
              }}
            </span>
          </el-form-item>
          <el-form-item label="推广员手机号" :required="true">
            <el-input
              placeholder="输入11位数手机号码"
              v-model="ruleForm.p_mobile"
              style="width: 360px"
              :maxlength="11"
            ></el-input>
            <span class="tip">* 推广员首次进入推广中心需要验证该手机号</span>
          </el-form-item>
          <el-form-item label="真实姓名">
            <div slot="label" style="padding-left: 10px">真实姓名</div>
            <el-input
              placeholder="输入姓名"
              v-model="ruleForm.p_realname"
              style="width: 360px"
              :maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构(单位)">
            <div slot="label" style="padding-left: 10px">机构(单位)</div>
            <el-input
              :maxlength="50"
              placeholder="输入机构(单位)"
              v-model="ruleForm.p_company"
              style="width: 360px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" style="width: 74px" size="medium">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="submit"
          style="width: 74px"
          size="medium"
        >
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'addPromoter',

  data() {
    return {
      groupList: [],

      ruleForm: {
        p_group_id: '',
        p_mobile: '',
        p_realname: '',
        p_company: '',
      },

      options: [],
    }
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },

    selectTemplate: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    settlement_days() {
      let val = this.groupList.find((item) =>
        this.ruleForm.p_group_id
          ? item.p_group_id == this.ruleForm.p_group_id
          : item.p_group_id == this.selectTemplate.p_group_id
      )

      if (val) {
        return val.settlement_days
      } else {
        return ''
      }
    },
  },

  created() {
    this.getgroupList()
    this.ruleForm.p_group_id = this.selectTemplate.p_group_id
  },

  methods: {
    // 保存
    submit() {
      if (!this.ruleForm.p_group_id) {
        this.$root.prompt('请选择分组')
        return
      }
      if (!this.ruleForm.p_mobile.trim()) {
        this.$root.prompt('请输入手机号')
        return
      }

      if (!/^1[3-9]\d{9}$/.test(this.ruleForm.p_mobile)) {
        this.$root.prompt('手机号码有误，请重填')
        return
      }
      this.addPromoter()
    },

    async addPromoter() {
      await this.$http({
        url: '/Promoter/addPromoter',
        data: this.ruleForm,
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.cancel()
    },

    // 推广员分组
    async getgroupList() {
      const { data } = await this.$http({
        url: '/Promoter/groupList',
        data: {},
      })
      this.groupList = data
    },

    cancel() {
      this.$emit('reset')
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#addPromoter {
  ::v-deep .el-dialog__header {
    padding-bottom: 0px !important;
  }
  .tip {
    font-size: 12px;
    color: #9b9b9b;
    line-height: 1.5;
    margin-left: 10px;
  }
}
</style>