import { render, staticRenderFns } from "./newGroup.vue?vue&type=template&id=319c0697&scoped=true&"
import script from "./newGroup.vue?vue&type=script&lang=js&"
export * from "./newGroup.vue?vue&type=script&lang=js&"
import style0 from "./newGroup.vue?vue&type=style&index=0&id=319c0697&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319c0697",
  null
  
)

export default component.exports